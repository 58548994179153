import {
  Parking,
  RunWizard,
  SearchBar,
  parkingsGql,
  useRootStore as useRootStoreOSP,
} from '@gimlite/osp';
import { dispatch, useLocation, useNavigate } from '@gimlite/router';
import {
  Main,
  navigationService,
  requestGQL,
  toCapitalizeCase,
  useRootStore as useRootStoreWatermelon,
  useTranslation,
} from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import OspFull from './assets/logos/osp_full';
import LogoPoolOSP from './assets/logos/osp_pool';
import { Me } from './common/types/entities/me';

export const MainUse = observer(() => {
  const { AuthStore: AuthStoreWatermelon } = useRootStoreWatermelon();
  const { GlobalStore: GlobalStoreOSP } = useRootStoreOSP();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t, lang } = useTranslation();

  const me = AuthStoreWatermelon.me as Me | null;

  const [parkings, setParkings] = useState<Parking[]>([]);

  useEffect(() => {
    dispatch('I18N', {
      operationName: 'i18n',
      operationType: 'QUERY',
      params: { key: 'bff-operators-global-v2' },
    });

    AuthStoreWatermelon.initMe();
  }, []);

  useEffect(() => {
    if (me) {
      requestGQL({
        operationName: 'parkings',
        params: {},
        gql: parkingsGql,
        render: (data) => data?.list,
      }).then((data) => setParkings(data));

      const { firstName, lastName, lang } = me;

      AuthStoreWatermelon.setNames({ firstName, lastName });

      // Select user language
      dispatch('SELECTED_LANG', { lang });

      dispatch('I18N', {
        operationName: 'i18n',
        operationType: 'QUERY',
        params: { key: `bff-operators-${me._id}` },
      });
    }
  }, [me]);

  useEffect(() => {
    if (me) {
      const { firstName, lastName } = me;
      // TODO : use correctly translation keys
      // Show personnal account informations
      dispatch('ME_INFO', {
        info: [
          {
            key: 'name',
            label: t('watermelon-name') || 'NAME',
            value: toCapitalizeCase(`${firstName}`),
          },
          {
            key: 'operatorCode',
            label: t('watermelon-operator-code') || 'OPERATOR CODE',
            value: toCapitalizeCase(`${lastName}`),
          },
        ],
      });
    }
  }, [me, lang]);

  useEffect(() => {
    // Handle redirection at first loading
    if (pathname === '/' && parkings && parkings.length > 0) {
      navigate(`/${parkings[0]._id}/dashboard`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parkings]);

  const menuItems = useMemo(() => {
    if (!parkings || !parkings.length || parkings.length === 0) return [];

    return [
      {
        key: 'top',
        position: 'top',
        title: t('parkings'),
        items: parkings.map((p: Parking) => ({
          key: `/${p._id}/dashboard`,
          label: p.name,
          icon: 'faSquareParkingSolid',
          items: [
            {
              key: `/${p._id}/dashboard`,
              label: t('dashboard'),
              icon: 'faHomeSolid',
            },
            {
              key: `/${p._id}/sessions`,
              label: t('sessions'),
              icon: 'faClockSolid',
            },
            {
              key: `/${p._id}/contracts`,
              label: t('contracts'),
              icon: 'faIdCardSolid',
              extra: (
                <RunWizard
                  handleEvent={{
                    click: () => {
                      dispatch('SHOW_WIZARD', {
                        id: 'choiceCreateContract',
                        store: {
                          parkingId: p._id,
                          actions: {
                            createNewSingleContract: false,
                            createNewMasterPoolContract: false,
                          },
                          mode: GlobalStoreOSP.mode,
                        },
                      });
                    },
                  }}
                />
              ),
            },
            {
              key: `/${p._id}/incidents`,
              label: t('incidents'),
              icon: 'faCircleExclamationSolid',
            },
          ],
        })),
      },
      {
        position: 'top',
        title: t('myCompany'),
        items: [
          {
            key: '/motorists',
            label: t('motorists'),
            icon: 'faUserSolid',
            extra: (
              <RunWizard
                handleEvent={{
                  click: () =>
                    dispatch('SHOW_WIZARD', {
                      id: 'createSeveralMotorists',
                    }),
                }}
              />
            ),
          },
        ],
      },
    ];
  }, [parkings, lang]);

  useEffect(() => {
    navigationService.send('REGISTER_MENU', {
      menu: menuItems,
    });
  }, [menuItems]);

  return (
    <Main
      handleEvent={{
        selected: (key: string) => {
          if (key === 'logout') {
            dispatch('LOGOUT', {});
            navigate('/');
          } else {
            navigate(key);
          }
        },
      }}
      config={{
        navigation: {
          logo: LogoPoolOSP(t),
          mode: 'drop',
        },
        header: {
          logo: OspFull(),
          extra: <SearchBar></SearchBar>,
        },
      }}
    />
  );
});
